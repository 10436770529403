import React from 'react'
import alertsContext from './alertsContext.js'
import { _alertsContext } from './internal/__alertsContext.js'

export default function AlertsManager ({children}) {
  React.useEffect(() => {
    console.warn('AlertsManager is deprecated, please remove this component from your code and switch any remaining alertsContext usage over to @react-spectrum/toast ToastContainer')
  }, [])

  return (
    <alertsContext.Provider value={_alertsContext}>
      {children}
    </alertsContext.Provider>
  )
}
